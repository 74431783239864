import React from "react";

function Testimonial() {
  return (
    <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
          width="784"
          height="404"
          fill="none"
          viewBox="0 0 784 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="784"
            height="404"
            fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
          />
        </svg>

        <svg
          className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="56409614-3d62-4985-9a10-7ca758a8f4f0"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
          />
        </svg>

        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto h-8"
            viewBox="0 0 159 62"
            fill="#111827"
            stroke="none"
          >
            <path d="M136.195 47.9248L125.716 17.6666H113.45L129.865 60.8753H142.585L159 17.6666H146.734L136.195 47.9248Z"></path>
            <path d="M20.5946 46.1054C24.3912 46.1054 27.86 45.1768 30.9738 43.3468C34.0876 41.4894 36.5185 39.0585 38.2392 35.972L29.4988 30.9736C28.7067 32.5851 27.5323 33.8416 25.9481 34.7429C24.3639 35.6443 22.5612 36.1086 20.5399 36.1086C17.5354 36.1086 15.0226 35.098 13.056 33.1041C11.0894 31.1102 10.1061 28.57 10.1061 25.5109C10.1061 22.3971 11.0894 19.8296 13.056 17.8357C15.0226 15.8418 17.5354 14.8312 20.5399 14.8312C22.5065 14.8312 24.2819 15.2955 25.8934 16.2242C27.4776 17.1529 28.6521 18.4366 29.4442 20.0481L38.1027 14.9678C36.4912 11.9086 34.1149 9.4504 31.0011 7.6477C27.8873 5.81767 24.4185 4.91632 20.6219 4.91632C14.7494 4.91632 9.86026 6.88291 5.92708 10.8434C1.96659 14.8039 0 19.6931 0 25.5382C0 31.356 1.96659 36.2452 5.89977 40.2057C9.80563 44.1389 14.7221 46.1054 20.5946 46.1054Z"></path>
            <path
              d="M38.4859 50.4456H1.6123V60.9614H38.4859V50.4456Z"
              fill="#2563EB"
            ></path>
            <path d="M79.921 24.8828L71.9727 11.7995V31.083L57.2779 6.88307H46.2158V61.4832H56.9774V26.1393L72.1092 51.0221V31.7659L79.6752 44.221V44.5214H79.8663L80.0302 44.8218V44.4668L95.3532 19.1742V54.6002H106.033V0H94.9709L79.921 24.8828Z"></path>
          </svg>

          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>
                &ldquo;Headless infrastructure is a valid candidate to become
                the mainstream for how e-commerce websites are built in the next
                10 years.&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    src="/lavrenov.jpg"
                    alt=""
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    Daniil Lavrenov
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-blue-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    VC, CM Ventures
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>

    // <section className="bg-gray-50 overflow-hidden">
    //   <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
    //     <svg
    //       className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
    //       width="784"
    //       height="404"
    //       fill="none"
    //       viewBox="0 0 784 404"
    //       aria-hidden="true"
    //     >
    //       <defs>
    //         <pattern
    //           id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
    //           x="0"
    //           y="0"
    //           width="20"
    //           height="20"
    //           patternUnits="userSpaceOnUse"
    //         >
    //           <rect
    //             x="0"
    //             y="0"
    //             width="4"
    //             height="4"
    //             className="text-gray-200"
    //             fill="currentColor"
    //           />
    //         </pattern>
    //       </defs>
    //       <rect
    //         width="784"
    //         height="404"
    //         fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
    //       />
    //     </svg>

    //     <svg
    //       className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
    //       width="404"
    //       height="784"
    //       fill="none"
    //       viewBox="0 0 404 784"
    //       aria-hidden="true"
    //     >
    //       <defs>
    //         <pattern
    //           id="56409614-3d62-4985-9a10-7ca758a8f4f0"
    //           x="0"
    //           y="0"
    //           width="20"
    //           height="20"
    //           patternUnits="userSpaceOnUse"
    //         >
    //           <rect
    //             x="0"
    //             y="0"
    //             width="4"
    //             height="4"
    //             className="text-gray-200"
    //             fill="currentColor"
    //           />
    //         </pattern>
    //       </defs>
    //       <rect
    //         width="404"
    //         height="784"
    //         fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
    //       />
    //     </svg>

    //     <div className="relative lg:flex lg:items-center">
    //       <div className="hidden lg:block lg:flex-shrink-0">
    //         <img
    //           className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
    //           src="https://media-exp1.licdn.com/dms/image/C4D03AQGuQoQOXSziKg/profile-displayphoto-shrink_100_100/0/1576224690319?e=1619049600&v=beta&t=uIMF4bq2VhYUBlRaGp5f63zHlWCD8mqOm-1avfUHDkE"
    //           alt=""
    //         />
    //       </div>

    //       <div className="relative lg:ml-10">
    //         <svg
    //           className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
    //           stroke="currentColor"
    //           fill="none"
    //           viewBox="0 0 144 144"
    //           aria-hidden="true"
    //         >
    //           <path
    //             strokeWidth="2"
    //             d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
    //           />
    //         </svg>
    //         <blockquote className="relative">
    //           <div className="text-2xl leading-9 font-medium text-gray-900">
    //             <p>
    //               Headless infrastructure is a valid candidate to become the
    //               mainstream for how e-commerce websites are built in the next
    //               10 years.
    //             </p>
    //           </div>
    //           <footer className="mt-8">
    //             <div className="flex">
    //               <div className="flex-shrink-0 lg:hidden">
    //                 <img
    //                   className="h-12 w-12 rounded-full"
    //                   src="https://media-exp1.licdn.com/dms/image/C4D03AQGuQoQOXSziKg/profile-displayphoto-shrink_100_100/0/1576224690319?e=1619049600&v=beta&t=uIMF4bq2VhYUBlRaGp5f63zHlWCD8mqOm-1avfUHDkE"
    //                   alt=""
    //                 />
    //               </div>
    //               <div className="ml-4 lg:ml-0">
    //                 <div className="text-base font-medium text-gray-900">
    //                   Daniil Lavrenov
    //                 </div>
    //                 <div className="text-base font-medium text-indigo-600">
    //                   VC, CM Ventures
    //                 </div>
    //               </div>
    //             </div>
    //           </footer>
    //         </blockquote>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
}

export default Testimonial;
