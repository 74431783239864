import React from "react";

function CTA() {
  return (
    <div className="bg-gradient-to-r from-blue-800 to-blue-700">
      <div className="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Ready to dive in?</span>
          <span className="block">Start your Headless Commerce today.</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-blue-200">
          We combined all the best technologies, practices and patterns to build
          a modern and high-performing Headless Commerce website.
        </p>
        <a
          href="/request-a-demo/"
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 sm:w-auto"
        >
          Request a Demo
        </a>
      </div>
    </div>
  );
}

export default CTA;
