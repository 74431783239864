import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Hero from "../components/SharedTailwind/Headless/Hero";
import Features from "../components/SharedTailwind/Headless/Features";
import Testimonial from "../components/SharedTailwind/Headless/Testimonial";
import SpeedTest from "../components/SharedTailwind/SpeedTest";
import CTA from "../components/SharedTailwind/Headless/Cta";
import PH from "../components/SharedTailwind/PH";
import FAQ from "../components/SharedTailwind/FAQ";

function HeadlessPage(props) {
  return (
    <React.Fragment>
      <Helmet title="Headless Commerce for Shopify">
        <meta
          name="description"
          content="Create a Headless Commerce solution for Shopify with Gatsby JS."
        />
      </Helmet>
      <Hero />
      <Features />
      <Testimonial />
      <PH />
      <SpeedTest />
      <CTA />
      <FAQ />
    </React.Fragment>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default HeadlessPage;
